//import React, { useState } from 'react';
import useAutoLogout from '../hooks/useAutoLogout'; // Import the custom hook
import './Dashboard.css'; // Import CSS for styling
import './buttons.css';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const navigate = useNavigate();
    const userName = localStorage.getItem('username'); 
    const timeLeft = useAutoLogout(); // 15 min in sec
    

    const handleLogout = () => {
      localStorage.removeItem('token');
      navigate('/sign-in'); // Redirect to sign-in page
    };

    const GoCreateCard = () => {
        navigate('/.flashupload');
    };

    const GoManageCard = () => {
        navigate('/flashcard-list');
    };

    const GoGameModeSelection =() => {
      navigate('/game-mode');
    }

    const GoStatistic =() => {
        navigate('/statistic');
    }

    return (
        <div className="dashboard-container">
            <h2>Welcome to {userName ? `${userName}'s` : 'User'} Dashboard</h2>
            <p>Time left before automatic logout: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</p>
            <div className="dashboard-blocks">
                <div className="dashboard-block" onClick={GoCreateCard}>
                    <h3>Create Card</h3>
                </div>
                <div className="dashboard-block" onClick={GoManageCard}>
                    <h3>Manage Card List</h3>
                </div>
                <div className="dashboard-block" onClick={GoGameModeSelection}>
                    <h3>Play</h3>
                </div>
                <div className="dashboard-block" onClick={GoStatistic}>
                    <h3>Statistic</h3>
                </div>
            </div>
            <button className='logout' onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default Dashboard;