import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2'; // Install chart.js and react-chartjs-2
import './buttons.css';
import './Statistic.css';
import { Swiper, SwiperSlide } from 'swiper/react';  // Import Swiper and SwiperSlide
import 'swiper/swiper-bundle.css';  // Import Swiper's styles

// Register required components
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend);

function Statistic() {
    const navigate = useNavigate();
    const [totalFlashcards, setTotalFlashcards] = useState(0); // Total flashcards from the database
    const [error, setError] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [correctionData, setCorrectionData] = useState({});
    const [highestCards, setHighestCards] = useState([]);
    const [lowestCards, setLowestCards] = useState([]);
    const [timeRange, setTimeRange] = useState('daily');

    const fetchTotalFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`);
            setTotalFlashcards(response.data.length); // Set total flashcards
        } catch (error) {
            console.error('Error fetching flashcards:', error);
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        fetchTotalFlashcards();
    }, [fetchTotalFlashcards]);

    const fetchStats = async () => {
        const userId = localStorage.getItem('userId');
        try {
            const correctionResponse = await axios.get(
                `${API_BASE_URL}/api/stats/correction-rate?user_id=${userId}&range=${timeRange}`
            );
            console.log('Fetching:', `${API_BASE_URL}/api/stats/correction-rate?user_id=${userId}&range=${timeRange}`);
            const flashcardsResponse = await axios.get(
                `${API_BASE_URL}/api/stats/top-flashcards?user_id=${userId}`
            );

            setCorrectionData(correctionResponse.data);
            setHighestCards(flashcardsResponse.data.highest);
            setLowestCards(flashcardsResponse.data.lowest);
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    useEffect(() => {
        fetchStats();
    }, [timeRange]);

    const chartData = useMemo(() => {
        if (!correctionData.length) {
            return {
                labels: [],
                datasets: [
                    {
                        label: `Correction Rate (${timeRange})`,
                        data: [],
                        backgroundColor: 'rgba(75,192,192,0.6)',
                        borderColor: 'rgba(75,192,192,1)',
                        borderWidth: 2,
                        fill: false,
                    },
                ],
            };
        }
    
        const labels = correctionData.map(entry => entry.created_at || entry.game_date); // X-axis
        const data = correctionData.map(entry => parseFloat(parseFloat(entry.correction_rate).toFixed(2))); // Y-axis
    
        const colorMapping = {
            daily: 'rgba(75,192,192,0.6)', // Green
            weekly: 'rgba(54,162,235,0.6)', // Blue
            monthly: 'rgba(255,206,86,0.6)', // Yellow
            yearly: 'rgba(255,99,132,0.6)', // Red
        };
    
        const backgroundColor = colorMapping[timeRange] || 'rgba(75,192,192,0.6)';
        const borderColor = backgroundColor.replace('0.6', '1'); // Slightly darker border
    
        return {
            labels,
            datasets: [
                {
                    label: `Correction Rate (${timeRange})`,
                    data,
                    backgroundColor,
                    borderColor,
                    borderWidth: 2,
                    fill: false,
                },
            ],
        };
    }, [correctionData, timeRange]);

    const GoDashboard = () => {
        navigate('/dashboard'); // Back to the home page.
    };

    return (
        <div className="statistic-container">
            <h2>Your Score Report</h2>
            <button className="back" onClick={GoDashboard}>Back</button>
            <div className="flashcards-info">
                Total Cards : {totalFlashcards}
            </div>
            <h3>Statistics</h3>
            <select className="dropdown" onChange={(e) => setTimeRange(e.target.value)} value={timeRange}>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
            </select>

            <div className="chart-container">
                <h3>Correction Rate Chart</h3>
                {chartData ? <Line data={chartData} /> : <p>Loading...</p>}
            </div>

            {/* Swiper Carousel for Highest Correction Rate Cards */}
            <div className="card-stats">
                <h3>Top 10 Highest Correction Rate Cards</h3>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    grabCursor={true} // Enables drag/swipe with mouse
                    breakpoints={{
                        320: { slidesPerView: 1 },  // Mobile
                        480: { slidesPerView: 1.5 }, // Small tablets
                        768: { slidesPerView: 2 },  // Tablets
                        1024: { slidesPerView: 3 }, // Desktop
                        1200: { slidesPerView: 4 }, // Large desktop
                    }}
                    loop={true}
                >
                    {highestCards.map((card) => (
                        <SwiperSlide key={card.id} className="carousel-card">
                            <div className="carousel-card-content">
                                <h4>{card.front_content}</h4>
                                <p>Rate: {parseFloat(card.correction_rate).toFixed(2) || 0} %</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {/* Swiper Carousel for Lowest Correction Rate Cards */}
            <div className="card-stats">
                <h3>Top 10 Lowest Correction Rate Cards</h3>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    grabCursor={true} // Enables drag/swipe with mouse
                    breakpoints={{
                        320: { slidesPerView: 1 },  // Mobile
                        480: { slidesPerView: 1.5 }, // Small tablets
                        768: { slidesPerView: 2 },  // Tablets
                        1024: { slidesPerView: 3 }, // Desktop
                        1200: { slidesPerView: 4 }, // Large desktop
                    }}
                    loop={true}
                >
                    {lowestCards.map((card) => (
                        <SwiperSlide key={card.id} className="carousel-card">
                            <div className="carousel-card-content">
                                <h4>{card.front_content}</h4>
                                <p>Rate: {parseFloat(card.correction_rate).toFixed(2) || 0} %</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default Statistic;
