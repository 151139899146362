import axios from 'axios';

export const fetchFoldersFromAPI = async (API_BASE_URL, userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/flashcards/folders?user_id=${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw error;
  }
};


export const createNewFolderFromAPI = async (API_BASE_URL, userId, folderName) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/flashcards/folders`, {
      user_id: userId,
      folder_name: folderName,
    });
    return response.data.folder;
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error;
  }
};
