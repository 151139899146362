import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FlashCard from './FlashCard'; // Import the FlashCard component
import './buttons.css';

function Play() {
    const location = useLocation();
    const navigate = useNavigate();
    const { mode, customAmount, folder } = location.state || { mode: 'all'};
    const [selectedFolder, setSelectedFolder] = useState(''); // Selected folder
    const [flashcards, setFlashcards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [reactionTimes, setReactionTimes] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [isFlipped, setIsFlipped] = useState(false); // Track if the card is flipped
    const [totalCards, setTotalCards] = useState(0); // Track total number of cards
    const scoreRef = useRef(score);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Use useCallback to memoize the fetchFlashcards function
    const fetchFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`, {
                params: { folder: folder || '' },
            });
            let cards = response.data;
            if (mode === 'custom') {
                cards = cards.sort(() => Math.random() - 0.5).slice(0, customAmount);
            } else {
                cards = cards.sort(() => Math.random() - 0.5);
            }
            setFlashcards(cards);
            setTotalCards(cards.length); // Set total number of cards
        } catch (error) {
            console.error('Error fetching flashcards:', error);
        }
    }, [mode, customAmount, API_BASE_URL, selectedFolder]); // Include API_BASE_URL in the dependencies

    useEffect(() => {
        const fetchAndSetStartTime = async () => {
            await fetchFlashcards();
            setStartTime(Date.now());
        };
        fetchAndSetStartTime();
        //fetchFlashcards();
    }, [fetchFlashcards]); // Include fetchFlashcards in the dependency array


    

    const handleAnswer = async (isCorrect) => {
        const reactionTime = (Date.now() - startTime) / 1000; // Calculate reaction time in seconds
        setReactionTimes((prev) => [...prev, reactionTime]); // Update reaction times

        const currentCard = flashcards[currentCardIndex];
        const updatedFlashcard = {
            id: currentCard.id,
            tested_time: 1,
            correct_time: isCorrect ? 1:0,
        };
        //update backend with the new tested and correct time
        try {
            await axios.put(`${API_BASE_URL}/api/scores/flashcards`, updatedFlashcard);
        } catch (error) {
            console.error('Error updating flashcard stats:', error);
        }

        if (isCorrect) {
            setScore((prev) => prev + 1); // Increment score if correct
        }
            scoreRef.current = score + (isCorrect ? 1 : 0);
            nextCard(); // Move to the next card after answering
        
    };

    const nextCard = () => {
        if (currentCardIndex < flashcards.length - 1) {
            setCurrentCardIndex((prev) => prev + 1);
            setStartTime(Date.now()); // Reset start time for the next card
            setIsFlipped(false); // Reset flip state for the next card to show front
        } else {
            setTimeout(() => {
                endGame();
            },0);
        }
    };


    const endGame = async () => {
        const userId = localStorage.getItem('userId');
        const totalReactionTime = Math.round(reactionTimes.reduce((a, b) => a + b, 0)); // Total reaction time in seconds
        const correctionRate = ((scoreRef.current/totalCards)*100).toFixed(2); // only allow two digit after decimal point.
        console.log('Score to database is', {scoreRef});
        try {
            await axios.post(`${API_BASE_URL}/api/scores`, {
                user_id: userId,
                score: scoreRef.current,
                total_reaction_time: totalReactionTime, // in seconds
                total_cards: totalCards, // Send total number of cards
            });
            alert(`Game Over! \nTotal Cards: ${totalCards} \nYour score: ${scoreRef.current} \nCorrection rate: ${correctionRate}%`);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error submitting score:', error);
        }
    };

    const handleQuit = () => {
        endGame(); // Call endGame to submit the score
    };


    if (flashcards.length === 0) return <p>Loading...</p>;

    const currentCard = flashcards[currentCardIndex];

    return (
        <div>
            <h2>Score: {scoreRef.current}</h2>
            <FlashCard 
                card={currentCard} 
                onFlip={() => setIsFlipped(prev => !prev)} // Toggle the flip state
                isFlipped={isFlipped} // Pass the isFlipped state
            />
            {/* Render buttons only when the card is flipped to the back */}
            {isFlipped && (
                <div className="answer-buttons">
                    <button className="correct" onClick={() => handleAnswer(true)}>Correct</button>
                    <button className="incorrect" onClick={() => handleAnswer(false)}>Incorrect</button>
                </div>
            )}
            <div className="navigation-buttons">
                <button className="quit" onClick={handleQuit}>Quit</button>
            </div>
        </div>
    );
}

export default Play;
