import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CreateFlashCard from './CreateFlashCard';
import { createNewFolderFromAPI, fetchFoldersFromAPI } from './FolderUtils';
import './FileUpload.css';
import './buttons.css';
import './Dashboard.css';



function FileUpload() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [generating, setGenerating] = useState(false);
  const [message, setMessage] = useState('');
  const [extractedText, setExtractedText] = useState('');
  const [showAiDialog, setAiDialog] = useState('');
  const blockRef = useRef(null); // Create a reference to the block element
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFetchFolders = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    try {
      const folderData = await fetchFoldersFromAPI(API_BASE_URL, userId);
      setFolders(folderData);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  }, [API_BASE_URL]);

  const handleCreateFolder = async () => {
    const folderName = prompt('Please enter folder name:');
    if (!folderName) return;
    const userId = localStorage.getItem('userId');

    try {
      const newFolder = await createNewFolderFromAPI(API_BASE_URL, userId, folderName);
      setFolders((prev) => [...prev, newFolder]);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  useEffect(() => {
    handleFetchFolders();
  }, [handleFetchFolders]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the block
      if (blockRef.current && !blockRef.current.contains(event.target)) {
        setAiDialog(false); // Close the dialog if the click is outside
      }
    };

    // Add event listener for clicks outside the dialog
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file first!');
      return;
    }

    setLoading(true);
    setMessage('Uploading...');

    const formData = new FormData();
    formData.append('file', file);
    const userId = localStorage.getItem('userId'); // get user id from local storage
    formData.append('user_id', userId); //append user id to form data
    

    try {
      const response = await axios.post(`${API_BASE_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('File upload response:', response.data);
      if (response.data.success) {
        setMessage('File uploaded successfully!');
        console.log('Extracting text from:', response.data.file.file_path); // Log the file path for extraction
        await handleExtractText(response.data.file.file_path); //extract text after upload
      }
    } catch (error) {
      console.error('Upload error:', error);
      setMessage('Error uploading file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleExtractText = async (filePath) => {
    setLoading(true);
    setMessage('Extracting text...');

    try {
      const response = await axios.post(`${API_BASE_URL}/api/extract`, { filePath });
      if (response.data.success) {
        setExtractedText(response.data.text);
        setMessage('Text extracted successfully!');
        await handleGenerateFlashcards(response.data.text); // Generate flashcards after extraction
      } else {
        setMessage('Error extracting text');
      }
    } catch (error) {
      console.error('Error extracting text:', error);
      setMessage('Error extracting text');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateFlashcards = async (text) => {
    if (text.length > 1000) {
      setMessage('Text exceeds 1000 words limit. Please provide shorter content.');
      return;
    }
  const userId = localStorage.getItem('userId'); //get user ID from local storage
  
  console.log('User ID:', userId);
  console.log('Text length', text.length);
  setGenerating(true);

  try {
    const response = await axios.post(`${API_BASE_URL}/api/generateflashcards`, { text, user_id: userId, folder_name: selectedFolder });
    
    console.log('Request body:', { text, user_id: userId, folder_name: selectedFolder});
    
    if (response.data.success) {
      // Assuming response.data.flashcards contains the generated flashcards
      console.log('Generated flashcards:', response.data.flashcards);
      setMessage('Flashcards generate successfully!');
      // You can now pass these flashcards to CreateFlashCard or handle them as needed
    } else {
      setMessage('Error generating flashcards');
    }
  } catch (error) {
    console.error('Error generating flashcards:', error);
    setMessage('Error generating flashcards');
  } finally {
    setGenerating(false); //reset generating state
  }
};

  const GoDashboard = () => {
    navigate('/dashboard'); //Back to the home page.
 };

  return (
  <div className='dashboard-container'>
    <button className="back" onClick={GoDashboard}>Back</button>
    <div className='dashboard-blocks'>  
      <div className="dashboard-block"
          ref={blockRef} // Attach ref to the block
          onClick={(e) => {
            // Prevent click propagation inside block
            e.stopPropagation(); 
            setAiDialog(true); // Open the dialog when clicked
          }}
      >
        <h2>AI Generate</h2>
        {showAiDialog && (
          <div className='create-container'>
            <form onSubmit={handleSubmit}>
                <button  className='back' type="button" onClick={handleCreateFolder}>
                  Create New Cards' Folder
                </button>
              <select className='dropdown'
                  value={selectedFolder}
                  onChange={(e) => setSelectedFolder(e.target.value)}
                  
              >
                  <option value="">Select Folder</option>
                  {folders.map((folder) => (
                    <option key={folder.id} value={folder.folder_name}>
                      {folder.folder_name}
                    </option>
                  ))}
                </select>
              <div>
                <label className='file-label'>
                  Choose File
                  <input type="file" onChange={handleFileChange} style={{display: 'none'}} />
                </label>
                {file && <p >Selected file: {file.name}</p>}
              </div>
              <div>
                <button className='upload-button' type="submit"  disabled={loading}>
                  {loading ? 'Uploading...' : 'Create'}
                </button>
              </div>
            </form>
            {extractedText && (
              <div>
                <h3>Extracted Text</h3>
                <textarea className='text-output' value={extractedText} readOnly rows="20" cols="80" style={{ width: '100%' }} />
              </div>
            )}
            {message && <p className="message">{message}</p>}
            {generating && <p>Generating Flashcards...</p>}
        </div>
        )}
      </div>
          <CreateFlashCard />
    </div>
  </div>
);
}

export default FileUpload;
