import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createNewFolderFromAPI, fetchFoldersFromAPI } from './FolderUtils';
import axios from 'axios';
import './buttons.css';

function GameModeSelection() {
    const navigate = useNavigate();
    const [folders, setFolders] = useState([]); // List of folders
    const [selectedFolder, setSelectedFolder] = useState(''); // Selected folder
    const [customAmount, setCustomAmount] = useState(1); // Default to 1
    const [totalFlashcards, setTotalFlashcards] = useState(0); // Total flashcards from the database
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Fetch folders
    const fetchFolders = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const folderData = await fetchFoldersFromAPI(API_BASE_URL, userId);
            setFolders(folderData);
        } catch (error) {
            console.error('Error fetching folders:', error);
            setError('Error fetching folders');
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        fetchFolders(); 
    }, [fetchFolders]);

    // Fetchflashcards for selected folder
    const fetchTotalFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        //if (!selectedFolder) return;
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`, {
                params: { folder: selectedFolder || '' },
            });
            setTotalFlashcards(response.data.length); // Set total flashcards
            setLoading(false);
        } catch (error) {
            console.error('Error fetching flashcards:', error);
        }
    }, [API_BASE_URL, selectedFolder]); // Include API_BASE_URL if it can change

    useEffect(() => {
        fetchTotalFlashcards();
    }, [fetchTotalFlashcards]); // Include fetchTotalFlashcards in the dependency array

    const handleStartGame = () => {
        if (customAmount < 1 || customAmount > totalFlashcards) {
            setError(`Please select a number between 1 and ${totalFlashcards}.`);
        } else {
            setError(''); // Clear any previous error
            navigate('/play', { state: { mode: 'custom', customAmount, folder:selectedFolder } });
        }
    };

    const GoDashboard = () => {
        navigate('/dashboard'); // Back to the home page.
    };

    return (
        <div>
            <h2>Select Game Mode</h2>
            <button className="back" onClick={GoDashboard}>Back</button>
            <div class="custom-amount-container">
            <label for="custom-amount-input">Selected A Folder then Select Amount/Shuffle(1 to <span class="total-flashcards">{totalFlashcards}</span>):</label>
            <select value={selectedFolder} onChange={(e) => setSelectedFolder(e.target.value) } className="dropdown">
                <option value="">All Folders {selectedFolder=="" && `(${totalFlashcards})`}</option>
                {folders.map((folder) => (
                    <option key={folder.id} value={folder.folder_name}>{folder.folder_name} {selectedFolder===folder.folder_name && `(${totalFlashcards})`}</option>
                ))}
            </select>
            <select
                className='dropdown'
                id="custom-amount-dropdown"
                value={customAmount}
                onChange={(e) => setCustomAmount(Number(e.target.value))}
            >
                {/* Dynamically generate options from 1 to totalFlashcards */}
                {Array.from({ length: totalFlashcards }, (_, index) => index + 1).map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))}
            </select>
                <button className="start" onClick={handleStartGame}>Start Game</button>
                <button className="shuffle" onClick={() => navigate('/play', { state: { mode: 'all', folder:selectedFolder } })}>Quick Start <br/> Shuffle All</button>
                {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
            </div>
        </div>
    );
}

export default GameModeSelection;
